import React, { Component } from "react"

class TagEvents extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    window.gtag("conversion", "click", {
      send_to: ["AW-638517927/e99BCP225c8BEKeFvLAC"],
    })
  }
  render() {
    return <div onLoad={this.handleClick}></div>
  }
}

export default TagEvents
