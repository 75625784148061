import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import TagEvents from "../components/tagevents"
import SuccessCartClear from "../components/Cart/successPageCartClear"

const SuccessPage = () => (
  <Layout>
    <SEO
      title="Success"
      keywords={[
        `australian`,
        `exclusive`,
        `jewellery`,
        `engagement`,
        `wedding`,
      ]}
    />
    <TagEvents />
    <div className="avwrap">
      <div className="avtext">
        <div className="avtitle">Order received</div>
        A copy of your receipt has been emailed to you. We'll be in touch as the
        piece progresses.
        <br />
        <div className="avlink">
          <Link to="/collection">Go back to Collection.</Link>
        </div>
      </div>
    </div>
    <SuccessCartClear />
  </Layout>
)

export default SuccessPage
