import React, { useContext, useEffect } from "react"
import { CartContext } from "./CartProvider"

const SuccessCartClear = () => {
  const { cartClear } = useContext(CartContext)

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (document.referrer === "https://checkout.stripe.com/") {
        //console.log("Clearning Cart")
        cartClear()
      }
    }
  })
  return <></>
}

export default SuccessCartClear
